(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpFileUpload', hpFileUploadDirective);
    hpFileUploadDirective.$inject = ['$log', '$timeout', 'toaster', 'UploadService'];
    function hpFileUploadDirective ($log, $timeout, toaster, UploadService) {
        return {
            restrict: 'E',
            template: `
                <div ng-class="{'isUploaded': fileupload.uploadId}">
                    <button
                        ng-if="!disabled"
                        type="button"
                        class="btnUpload ion-paperclip"
                        ngf-select="fileupload.upload($file)"
                        accept="*/*"
                        ngf-multiple="false"
                        ngf-max-size="80MB">
                    </button>
                    <a ng-if="fileupload.uploadId"
                        ng-href="/v1/agent/uploaded/{{fileupload.uploadId}}/url"
                        class="btnView"
                        target="_blank"><!--
                     --><span class="upload-description"><!--
                         --><span class="ion-ios-eye-outline"></span> <!--
                         --><span class="upload-id">({{fileupload.uploadId}})</span> <!--
                         --><span class="filename">Uploaded File</span><!--
                     --></span><!--
                 --></a>
                    <button
                        ng-if="fileupload.uploadId && !disabled"
                        class="btnRemove ion-ios-trash-outline"
                        ng-click="fileupload.remove()"
                        target="_blank">
                    </button>
                </div>
            `,
            require: 'ngModel',
            replace: true,
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                $scope.fileupload = {};
                $attrs.$observe('disabled', function (val) { $scope.disabled = val; });

                $scope.fileupload.upload = function (file) {
                    $log.info(file);
                    if (!file) return;
                    toaster.pop('success', 'Image Upload', 'Upload started. Please wait ...');

                    UploadService.uploadFile(file).then(function (upload) {
                        toaster.pop('success', 'Image Upload', 'Successful upload!');
                        $scope.fileupload.uploadId = upload.id;

                        ngModelCtrl.$setViewValue(upload.id);
                    }, function (err) {
                        $log.info(err);
                        toaster.pop('error', 'Image Upload', 'Failed to upload file. Try again later.');
                    });
                };

                $scope.fileupload.remove = function () {
                    $scope.fileupload.uploadId = null;
                    ngModelCtrl.$setViewValue(null);
                };

                ngModelCtrl.$render = function () {
                    $scope.fileupload.uploadId = ngModelCtrl.$viewValue;
                };
            }
        };
    }
})(window.angular, window._);
